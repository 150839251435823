import React, { Component, Fragment } from "react";
import arxs from "infra/arxs";
import ToggleButtons from "./FormItemControls/ToggleButtons";
import Instruction from "./FormItemControls/Instruction";
import Checkbox from "./FormItemControls/Checkbox";
import Remark from "./FormItemControls/Remark";
import PointsOfAttention from "./FormItemControls/PointsOfAttention";
import Icons from "./FormItemControls/Icons";
import Images from "./FormItemControls/Images";
import RiskAndPreventionMeasures from "./FormItemControls/RiskAndPreventionMeasures";
import Periodicals from "./FormItemControls/Periodicals";
import Recommendation from "./FormItemControls/Recommendation";
import RiskAnalysis from "./FormItemControls/RiskAnalysis";
import RiskMeasurement from "./FormItemControls/RiskMeasurement";
import PreRecommendation from "./FormItemControls/PreRecommendation";
import CodeElementFormLookup from "./FormItemControls/CodeElementFormLookup";
import Link from "./FormItemControls/Link";

import "./FormItem.scss";

export default class FormItem extends Component {

    constructor(props) {
        super(props);

        this.state = {
            attachmentsExpanded: false,
            item: this.props.item,
        };
    }

    onChangeControl = (value, expandAttachments) => {
        this.setState({ attachmentsExpanded: expandAttachments }
            , () => {
                if (this.props.onChange) {
                    this.props.onChange(value);
                }
            })
    }

    render() {
        const { attachmentsExpanded } = this.state;
        const { control, module, objectId, item, readOnly, requiredWithoutValue, card, data, attachmentInfo } = this.props;

        const defaultProps = {
            module: module,
            objectId: objectId,
            readOnly: readOnly,

            title: item.title,
            card,
            item,
            control,
            data,

            attachmentsExpanded,
            requiredWithoutValue,
            attachmentInfo: attachmentInfo,
            required: item.required,
            onChange: (value, expandAttachments) => this.onChangeControl(value, expandAttachments),
            onExpandAttachments: (value) => this.setState({ attachmentsExpanded: value }),
        };

        switch (control.type) {
            //readonly
            case "instruction":
                return <Instruction
                    {...defaultProps}
                />;
            //readonly with additional data
            case "pointsOfAttention":
                return <PointsOfAttention
                    {...defaultProps}
                />;
            case "icons":
                return <Icons
                    {...defaultProps}
                />;
            case "images":
                return <Images
                    {...defaultProps}
                />;
            case "riskAndPreventionMeasures":
                return <RiskAndPreventionMeasures
                    {...defaultProps}
                />;
            //executable
            case "select":
                return <ToggleButtons
                    {...defaultProps}
                />;
            case "checkbox":
                return <Checkbox
                    {...defaultProps}
                />;
            case "remark":
                return <Remark
                    {...defaultProps}
                />;
            //bi-mode
            case "riskAnalysis":
                return <RiskAnalysis
                    {...defaultProps}
                />;
            case "riskMeasurement":
                return <RiskMeasurement
                    {...defaultProps}
                />;
            case "recommendation":
                return <Recommendation
                    {...defaultProps}
                />;
            case "preRecommendation":
                return <PreRecommendation
                    {...defaultProps}
                />;
            case "link":
                return <Link
                    {...defaultProps}
                />;
            case "periodicals":
                return <Periodicals
                    {...defaultProps}
                />;
            case "requirements":
                return <CodeElementFormLookup
                    {...defaultProps}
                    code="InstructionCard.Requirements"
                    placeholder={arxs.t("controls.common.select")}
                />;
            case "additionalSafetyRequirements":
                return <CodeElementFormLookup
                    {...defaultProps}
                    code="Commissioning.AdditionalSafetyRequirements"
                    placeholder={arxs.t("controls.common.select")}
                />;
            default: return <Fragment></Fragment>;
        }
    }
}