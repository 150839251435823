import React, { useState, useEffect, useCallback } from "react";

import arxs from "infra/arxs";
import TreeList from "components/controls/treelist/TreeList";

import "./LibraryItemList.scss";

export default function LibraryItemList(props) {
    const [treeItems, setTreeItems] = useState([]);
    const [checked, setChecked] = useState([]);

    useEffect(() => {
        const treeItems = Object.entries((props.items || [])
            .filter(x => x.module === props.module)
            .groupBy(x => JSON.parse(x.metadata).targetModule))
            .map(gs => ({
                id: arxs.uuid.generate(),
                text: arxs.modules.titles[gs[0]],
                icon: arxs.modules.icons[gs[0]],
                items: Object.entries(gs[1].groupBy(x => JSON.parse(x.metadata).subjectModule))
                    .map(xs => ({
                        id: arxs.uuid.generate(),
                        text: arxs.modules.titles[xs[0]] || arxs.t("controls.library.import.common_module"),
                        icon: arxs.modules.icons[xs[0]] || "",
                        items: xs[1]
                            .map(x => ({
                                ...x,
                                text: x.title,
                            }))
                            .orderBy(x => x.text)
                    }))
            }));
        setTreeItems(treeItems);
    }, [props.items]);

    useEffect(() => {
        props.onChangeChecked(checked);
    }, [checked]);

    const onChecked = useCallback((item) => {
        setChecked(checked => checked.toggle(item, (l, r) => l.id === r.id));
    });

    return (
        <div className="library-item-list">
            <TreeList
                data={treeItems}
                onClick={props.onClick}
                onCheckToggle={onChecked}
                idField={"id"}
                allowBranchCheckboxes={false}
                />
        </div>
    );
}