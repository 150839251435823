import arxs from "infra/arxs";
import { OriginModuleEnum, TaskStatus } from "infra/api/contracts";
import TaskRequestActions from "modules/facilitymanagement/taskrequests/TaskRequestActions";
import { createCompleteTaskPopup } from "modules/facilitymanagement/tasks/CompleteTaskPopup";
import { createFollowUpTaskLikePopup } from "modules/facilitymanagement/tasks/FollowUpTaskLikePopup";
import { createAmendActualDurationTaskLikePopup } from "./AmendActualDurationTaskLikePopup";
import TasklikeActions from "./TasklikeActions";
class TaskActions extends TasklikeActions {
  constructor() {
    super(OriginModuleEnum.Task);
  }

  getConfiguration(arxs) {
    return {
      path: "/task",
      resource: arxs.ApiClient.facilitymanagement.task,
      translations: {
        "completed_confirmation": arxs.t("actions.task.completed_confirmation"),
        "not_all_required_fields_filled": arxs.t("actions.form.error.not_all_required_fields_filled_in"),
        "activate_confirmation": arxs.t("actions.task.activate_confirmation"),
        "activate_confirmation_question": arxs.t("actions.task.activate_confirmation_question"),
        "toprocess_confirmation": arxs.t("actions.task.toprocess_confirmation"),
        "toprocess_confirmation_question": arxs.t("actions.task.toprocess_confirmation_question"),
        "verified_confirmation": arxs.t("actions.task.verified_confirmation"),
        "verify_confirmation_question": arxs.t("actions.task.verify_confirmation_question"),
        "hold_confirmation": arxs.t("actions.task.hold_confirmation"),
        "hold_confirmation_question": arxs.t("actions.task.hold_confirmation_question"),
        "unhold_confirmation": arxs.t("actions.task.unhold_confirmation"),
        "unhold_confirmation_question": arxs.t("actions.task.unhold_confirmation_question"),
        "create_follow_up_confirmation": arxs.t("actions.task.create_follow_up_confirmation"),
        "create_follow_up": arxs.t("planning.follow_up_task.title"),
        "no_subject": arxs.t("actions.task.no_subject"),
        "create_task": arxs.actions.titles["create_task"],
        "create_riskanalysis": arxs.actions.titles["create_riskanalysis"],
        "create_inspection": arxs.actions.titles["create_inspection"]
      },
      createCompletePopup: createCompleteTaskPopup,
      createFollowUpPopup: createFollowUpTaskLikePopup,
      createAmendActualDurationPopup: createAmendActualDurationTaskLikePopup
    };
  }

  getActions(module) {
    return super.getActions(module)
      .concat(TaskRequestActions.getActions(OriginModuleEnum.NotificationDefect));
  }

  getGlobalActions(module) {
    let globalActions = [{
      name: "create_task",
      module: module,
      icon: arxs.actions.icons["create_task"],
      getTitle: () => arxs.actions.titles["create_task"],
      onClick: (state) => this.createTask(state, module),
    }];
    return globalActions;
  }

  createTask = (state, module) => {
    return this.createTasklike(state, module, OriginModuleEnum.Task, "/task/create");
  }

  createInspection = (state, module) => {
    return this.createTasklike(state, module, OriginModuleEnum.PeriodicControl, "/inspection/create");
  }

  createRiskAnalysis = (state, module) => {
    return this.createTasklike(state, module, OriginModuleEnum.RiskAnalysis, "/riskanalysis/create");
  }

  dropActions = () => {
    return {
      onDropInRefused: (state) => {
        TaskRequestActions.refuse(state);
      },
      onDropInPending: (state) => {
        TaskRequestActions.unrefuse(state);
      },
      onDropInInProcess: (state) => {
        switch (state.item.module) {
          case "Task":
            this.setInProcess(state);
            break;
          case "NotificationDefect":
            TaskRequestActions.accept(state);
            break;
          default:
            return;
        }
      },
      onDropInOnHold: (state) => {
        switch (state.item.module) {
          case "Task":
            switch (state.item.status) {
              case TaskStatus.InProcess:
              case TaskStatus.ExecutionOverdue:
              case TaskStatus.ToVerify:
              case TaskStatus.Active:
                this.hold(state);
                break;
              default:
                return;
            }
            break;
          case "NotificationDefect":
            TaskRequestActions.acceptAndHold(state);
            break;
          default:
            return;
        }
      },
      onDropInActive: (state) => {
        switch (state.item.module) {
          case "Task":
            switch (state.item.status) {
              case TaskStatus.InProcess:
                this.directActivate(state);
                break;
              case TaskStatus.OnHold:
              case TaskStatus.ToVerify:
                this.activate(state);
                break;
              default:
                return;
            }
            break;
          case "NotificationDefect":
            TaskRequestActions.acceptAndActivate(state);
            break;
          default:
            return;
        }
      },
      onDropInCompleted: (state) => {
        switch (state.item.module) {
          case "Task":
            switch (state.item.status) {
              case TaskStatus.InProcess:
              case TaskStatus.OnHold:
              case TaskStatus.ExecutionOverdue:
              case TaskStatus.Active:
                this.complete(state);
                break;
              case TaskStatus.ToVerify:
                this.verify(state);
                break;
              default:
                return;
            }
            break;
          case "NotificationDefect":
            TaskRequestActions.acceptAndComplete(state);
            break;
          default:
            return;
        }
      },
      onDropInMultiYearPlan: (state) => {
        this.transferToMultiYearPlan(state);
      }
    };
  }
}
export default new TaskActions();
