import React, { useState, useEffect } from "react";

import arxs from "infra/arxs";
import Toaster from "components/util/Toaster";
import Button from "components/controls/Button";
import FieldSet from "components/controls/FieldSet";

import "./PublishToLibraryPopup.scss";

export const createPublishToLibraryPopup = (
  cards,
  onCancel,
  title,
) => {
  const state = {
    title: title || arxs.t("actions.library.publish"),
    content: (
      <PublishToLibraryPopup
        cards={cards}
        onCancel={onCancel}
      />
    ),
    onCancel: onCancel,
    fitContentWidth: true,
    fitContentHeight: true,
    canMaximize: false,
  };

  return state;
};

function PublishToLibraryPopup(props) {
  const { cards, onCancel } = props;

  const [data, setData] = useState({ title: cards.length === 1 ? cards[0].title : null });

  const rows = [
    [
      {
        name: "title",
      },
    ],
  ];

  const publish = () => {
    arxs
    .ApiClient.masterdata.library
    .publish({
      title: data.title,
      links: cards.map(card => ({ module: card.module, id: card.id }))
    })
    .then(payload => {
      Toaster.success(arxs.t("controls.library.publish.submit_confirmation"));
      onCancel();
    });
  };

  return (
    <div className={`publish-to-library-popup ${props.className || ""}`}>
      <div className="publish-to-library-popup-body">
        {props.cards.length === 1 && <div className="content field">
          <FieldSet schemaName="Publish" rows={rows} data={data} setData={setData} />
        </div>}
        <div className="buttons">
          <Button className="icon" onClick={publish}>
            <i className="far fa-cloud-arrow-up"></i>
            {arxs.t("controls.library.publish.submit")}
          </Button>
          <Button className="icon alternative" onClick={onCancel}>
            {arxs.t("common.cancel")}
          </Button>
        </div>
      </div>
    </div>
  );
}
