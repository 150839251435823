import React, { useState, useEffect } from "react";
import { Spinner } from "components/animations/Spinner";
import Form from "components/controls/form/Form";
import arxs from "infra/arxs";
import { transformImageData, transformCodeElementData } from "./LibraryFormDataTransformations";

import "./LibraryItemPreview.scss";

export default function LibraryItemPreview(props) {
    const initialLookups = {
        codeElements: {},
    };

    const [payload, setPayload] = useState(null);
    const [lookups, setLookups] = useState(initialLookups);

    useEffect(() => {
        const subscriptions = {
            lookups: arxs.Api.lookups.subscribe(initialLookups, (values) => {
                setLookups((lookups) => ({ ...lookups, ...values }));
            }),
        };

        return () => {
            subscriptions.lookups.dispose();
        };
    }, []);

    useEffect(() => {
        if (props.selected) {
            setPayload(null); // Reset payload before fetching new data
    
            (async () => {
                try {
                    const response = await fetch(props.selected.url);
                    const data = await response.json();
                    let formDefinition = transformCodeElementData(data.formDefinition, lookups, data.targetModule);
                    formDefinition = await transformImageData(formDefinition);
                    setPayload({ ...data, formDefinition });
                } catch (error) {
                    console.error("Error fetching or transforming data:", error);
                }
            })();
        }
    }, [props.selected, lookups]);

    return (
        <div className="library-item-preview">
            {props.title && <h1>{props.title}</h1>}
            <div className="library-item-body-wrapper">
                {props.selected && !payload && <Spinner />}
                {payload && (
                    <div className="library-item-preview-body">
                        <Form
                            cards={[payload]}
                            module={payload.module}
                            readOnly={true}
                        />
                    </div>
                )}
            </div>
        </div>
    );
}
