import React, { useState, useEffect } from "react";
import {
    TreeView as KendoTreeView,
    processTreeViewItems
} from '@progress/kendo-react-treeview';

import arxs from "infra/arxs";
import { Spinner } from "components/animations/Spinner";
import Search from "components/controls/Search";
import CheckBox from "components/controls/CheckBox";

import "./TreeList.scss";


export interface TreeListItem {
    text: string;
    icon?: string;
    items: TreeListItem[];
}

export interface TreeListProps {
    data: TreeListItem[];
    onClick(item: TreeListItem): void;
    onCheckToggle(item: TreeListItem): void;
    idField: string;
    allowBranchCheckboxes?: boolean
}

export default function TreeList(props: TreeListProps) {
    const [searchTerm, setSearchTerm] = useState<string>();
    const [filteredTreeItems, setFilteredTreeItems] = useState<TreeListItem[]>([]);
    const [check, setCheck] = useState<Set<string>>(new Set());
    const [select, setSelect] = useState([""]);
    const [expand, setExpand] = useState<{ ids: Array<string>, idField: string }>({
        ids: [],
        idField: props.idField,
      });

    useEffect(() => {
        if (!searchTerm) {
            setFilteredTreeItems(props.data);
            setExpand({
                ids: [],
                idField: props.idField
            })
        } else {
            const st = searchTerm.toLowerCase();
            const matches = (item: TreeListItem) => item && item.text && item.text.toLowerCase().indexOf(st) > -1;
            
            const filter = (items: TreeListItem[]): TreeListItem[] => {
                const filtered = [];
                for (const item of items) {
                    if (matches(item)) {
                        filtered.push(item);
                    } else if (item.items && item.items.length > 0) {
                        const childItems = filter(item.items);
                        if (childItems.length > 0) {
                            filtered.push({
                                ...item,
                                items: childItems
                            });
                        }
                    }
                }
                return filtered;
            };

            const matchingTreeItems = filter(props.data);

            if (st) {
                setExpand({
                    ids: matchingTreeItems.flatMap(x => [(x as any)[props.idField], ...x.items.map(y => (y as any)[props.idField])]),
                    idField: props.idField
                })
            }

            setFilteredTreeItems(matchingTreeItems);
        }
    }, [props.data, searchTerm])

    const handleChangeSearchTerm = (event: any) => {
        setSearchTerm(event.target.value);
    };

    const onItemClick = (event: any) => {
        if (event.item.url) {
            props.onClick(event.item);
        }
        setSelect([event.itemHierarchicalIndex]);
        if  (event.item.items){
            onExpandChange(event);
        }
      };
    const onExpandChange = (event: any) => {
        const ids = expand.ids ? expand.ids.slice() : [];
        const index = ids.indexOf(event.item[props.idField]);
        index === -1 ? ids.push(event.item[props.idField]) : ids.splice(index, 1);
        setExpand({
            ids,
            idField: props.idField,
        });
    };   

    const countLeafChildren = (item: TreeListItem): number => {
        if (!Array.isArray(item.items) || item.items.length === 0) {
            return 1;
        }
    
        let count = 0;
        for (let child of item.items) {
            count += countLeafChildren(child);
        }
    
        return count;
    };

    const showTree = props.data && props.data.length > 0;

    const getLeafIds = (item: any): string[] => {
        if (!item.items || item.items.length === 0) {
            return [item.id];
        }
        return item.items.flatMap(getLeafIds);
    };
    
    const onCheckBoxChange = (event: any, item: any) => {
        event.stopPropagation();
    
        if (item.items && item.items.length > 0) {
            const leafIds = getLeafIds(item);
            const leafItems: any[] = [];
    
            const collectLeafItems = (node: any) => {
                if (!node.items || node.items.length === 0) {
                    leafItems.push(node);
                } else {
                    node.items.forEach(collectLeafItems);
                }
            };
    
            collectLeafItems(item);
    
            setCheck((prev: Set<string>) => {
                const next = new Set(prev);
                const allChecked = leafIds.every(id => next.has(id));
    
                leafItems.forEach(leaf => {
                    if (allChecked) {
                        next.delete(leaf.id);
                    } else {
                        next.add(leaf.id);
                    }
                    props.onCheckToggle(leaf); 
                });
    
                return next;
            });
        } else {
            setCheck((prev: Set<string>) => {
                const next = new Set(prev);
                if (next.has(item.id)) next.delete(item.id);
                else next.add(item.id);
                return next;
            });
    
            props.onCheckToggle(item);
        }
    };
    

    return (
        <div className="tree-list">
            <Search
                value={searchTerm}
                onChange={handleChangeSearchTerm}
                />
            {(!showTree || filteredTreeItems.length === 0) && <div className="center">
                {props.data ? <label>{arxs.t("controls.treelist.empty")}</label> : <Spinner />}
            </div>}
            {(showTree && filteredTreeItems.length > 0) && <KendoTreeView
                    data={processTreeViewItems(filteredTreeItems, {
                        select: select,
                        expand: expand,
                      })}
                    item={itemProps => {
                        return <div>
                            {((itemProps.item.items && props.allowBranchCheckboxes) || !itemProps.item.items )
                            && <CheckBox
                                onChange={(event:any) => onCheckBoxChange(event, itemProps.item)}
                                checked = {check.has(itemProps.item.id)}
                                ></CheckBox>
                            }
                            {itemProps.item.icon && <i className={itemProps.item.icon} ></i>}
                            {itemProps.item.text} {itemProps.item.items && `(${countLeafChildren(itemProps.item)})`}
                        </div>;
                    }}
                    expandIcons={true}
                    onExpandChange={onExpandChange}
                    aria-multiselectable={true}
                    onItemClick={onItemClick}
                />}
        </div>
    );
}