import { OriginModuleEnum } from "infra/api/contracts";

export async function transformImageData(formDefinition, uploadFn, generateIdFn) {
    const imageControl = formDefinition?.controls?.find(ctrl => ctrl.type === "images");
    if (!imageControl) return formDefinition;

    const updatedItems = await Promise.all(
        formDefinition.items.map(async (item) => {
            if (item.control !== imageControl.id || !item.data) return item;

            try {
                const rawImageDataList = JSON.parse(item.data);

                const transformedData = await Promise.all(
                    rawImageDataList.map(async (rec) => {
                        const binaryString = atob(rec.base64);
                        const byteArray = new Uint8Array(Array.from(binaryString, char => char.charCodeAt(0)));
                        const blob = new Blob([byteArray], { type: rec.contentType });
                        const url = URL.createObjectURL(blob);

                        const result = uploadFn
                            ? await uploadFn(url, rec.name, "FormImage", generateIdFn?.(), rec.contentType)
                            : {
                                id: rec.id,
                                url,
                                name: rec.name || "",
                                contentType: rec.contentType
                            };

                        if (uploadFn) {
                            URL.revokeObjectURL(url);
                        }
                        return result;
                    })
                );

                return { ...item, data: JSON.stringify(transformedData) };
            } catch (err) {
                console.error("Error transforming image data:", err);
                return item;
            }
        })
    );

    return {
        ...formDefinition,
        items: updatedItems
    };
}

function genericTransform({ item, lookups, field, lookupKey, matchBy = "code", targetField = field, source = "data" }) {
    try {
      const parsed = JSON.parse(item[source]);
      const elements = lookups?.codeElements?.[lookupKey]?.[0]?.children || [];
  
      const transformed = parsed.map(entry => {
        const match = elements.find(x => x[matchBy] === entry[field]);
        return {
          ...entry,
          [targetField]: match?.id || entry[field]
        };
      });
  
      return { ...item, [source]: JSON.stringify(transformed) };
    } catch (err) {
      console.error(`Error transforming ${lookupKey} data:`, err);
      return item;
    }
  }
  
  export function transformCodeElementData(formDefinition, lookups, module) {
    if (!formDefinition?.items || !formDefinition?.controls) return formDefinition;
  
    const controlTransformers = {
      riskMeasurement: (item) => {
        try {
          const parsed = JSON.parse(item.data);
  
          const domainElements = lookups?.codeElements?.["RiskAnalysis.Domain"]?.[0]?.children || [];
          const phaseElements = lookups?.codeElements?.["RiskAnalysis.Phase"]?.[0]?.children || [];
  
          const transformed = parsed.map(entry => {
            const domainMatch = domainElements.find(x => x.code === entry.domain);
            const phaseMatch = phaseElements.find(x => x.code === entry.phase || x.name === entry.phase);
  
            return {
              ...entry,
              domain: domainMatch?.id || entry.domain,
              phase: phaseMatch?.id || entry.phase
            };
          });
  
          return { ...item, data: JSON.stringify(transformed) };
        } catch (err) {
          console.error("Error transforming riskMeasurement data:", err);
          return item;
        }
      },
  
      periodicals: (item) => [
        genericTransform({ item, lookups, field: "executor", lookupKey: "Executor", matchBy: "name" }),
        genericTransform({ item, lookups, field: "frequency", lookupKey: "PeriodicMaintenance.IterationFrequency" })
      ].reduce((result, fnResult) => fnResult, item),
  
      recommendation: (item) =>
        genericTransform({ item, lookups, field: "conclusion", lookupKey: module === OriginModuleEnum.Commissioning ? "Commissioning.Conclusion" : "OutOfCommissioning.Conclusion", matchBy: "name" }),
  
      preRecommendation: (item) =>
        genericTransform({ item, lookups, field: "title", lookupKey: "Recommendation.PreAdvice", source: "settings" }),
  
      pointsOfAttention: (item) =>
        genericTransform({ item, lookups, field: "title", lookupKey: "IssueKind", source: "settings", matchBy: "name" })
    };
  
    const updatedItems = formDefinition.items.map(item => {
      const control = formDefinition.controls.find(ctrl => ctrl.id === item.control);
      const transformer = controlTransformers[control?.type];
  
      if (transformer && (item.data || item.settings)) {
        return transformer(item, lookups);
      }
  
      return item;
    });
  
    return {
      ...formDefinition,
      items: updatedItems
    };
  }
  