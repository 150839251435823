function b64EncodeUnicode(str: string) {
    // first we use encodeURIComponent to get percent-encoded Unicode,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(encodeURIComponent(str).replace(/%([0-9A-F]{2})/g,
        function toSolidBytes(match, p1) {
            return String.fromCharCode(parseInt(p1, 16));
    }));
}

class ImageProxy {
    resizeImage = (url: string, width: number, height: number) => {
        if (!url) {
            return null;
        }
        
        if (url.startsWith('blob:') || url.startsWith('data:')) {
            return url;
        }

        const base64 = b64EncodeUnicode(url)
        .replace(/=/g, '')
        .replace(/\+/g, '-')
        .replace(/\//g, '_');
        return `${process.env.REACT_APP_IMGPROXY_ENDPOINT}/insecure/rs:fill:${width}:${height}:0/g:ce/dpr:2/${base64}`;
    }
}
export default new ImageProxy();