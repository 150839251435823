import React, { Fragment } from "react";
import arxs from 'infra/arxs';
import FormItemEditorController from "./FormItemEditorController";
import GlobalContext from "infra/GlobalContext";
import AutoCompleteTextBox from "components/controls/AutoCompleteTextBox";
import { IconOverview } from "components/controls/images/IconOverview";

const DraggableFormListItem = (props) => {
    const { item, dragHandleProps, commonProps } = props;
    const { readOnly, onItemChange, onDeleteItem, codeElements } = commonProps;
    const risks = ((codeElements || {})["InstructionCard.Risks"] || [])[0] || {};
    const preventionMeasures = ((codeElements || {})["InstructionCard.PreventionRule"] || [])[0] || {};
    const risksValues = (risks.children || []).map(x => x.name);
    const preventionValues = (preventionMeasures.children || []).map(x => x.name);

    const getIcon = (value) => commonProps.iconMap?.[value] ? [commonProps.iconMap[value]] : [];
    const riskIcon = item.riskIcon ? getIcon(item.riskIcon) : [];
    const preventionIcon = item.preventionIcon ? getIcon(item.preventionIcon) : [];

    const onAddIcon = (state, field) => {
        if (state) {
            var icon = (Object.values(state) || []).map(x => x.iconId)[0];
            onItemChange({ ...item, [field]: icon });
        }
    }

    const onDeleteIcon = (field) => {
        var newData = { ...item };
        delete newData[field];
        onItemChange(newData);
    }

    const onTextChange = (state, field) => {
        let newData = { ...item };
    
        if (state) {
            newData[field] = state;
        } else {
            delete newData[field];
        }
    
        onItemChange(newData);
    };
    

    return (
        <div className="rpm-container" key={item.id}>
            {!readOnly && <div className="drag-handle" {...dragHandleProps}>
                <i className="fa fas fa-grip-vertical"></i>
            </div>}
            <div className="rpm">
                <IconOverview
                    className="field full-width"
                    key="risk-icons"
                    readOnly={readOnly}
                    data={riskIcon}
                    onAdd={(state) => onAddIcon(state, "riskIcon")}
                    onDelete={() => onDeleteIcon("riskIcon")}
                    singleSelection={true}
                    size={36}
                />
                <AutoCompleteTextBox
                    id={`risk-${item.id}`}
                    className="rpm-value"
                    items={risksValues}
                    value={item.risk}
                    onChange={(state) => onTextChange(state, "risk")}
                    readOnly={readOnly}
                    placeholder={arxs.t("forms.items.risk_prevention_measures.risk")}
                />
                <AutoCompleteTextBox
                    id={`prevention-${item.id}`}
                    className="rpm-value"
                    items={preventionValues}
                    value={item.prevention}
                    onChange={(state) => onTextChange(state, "prevention")}
                    readOnly={readOnly}
                    placeholder={arxs.t("forms.items.risk_prevention_measures.prevention")}
                />
                <IconOverview
                    className="field full-width"
                    key="prevention-icons"
                    readOnly={readOnly}
                    data={preventionIcon}
                    onAdd={(state) => onAddIcon(state, "preventionIcon")}
                    onDelete={() => onDeleteIcon("preventionIcon")}
                    singleSelection={true}
                    size={36}
                />
                <div className="rpm-actions">
                    {!readOnly && <div className="rpm-action" onClick={() => onDeleteItem(item.id)}>
                        <i className="far fa-trash-alt"></i>
                    </div>}
                </div>
            </div>
        </div>
    );
};

export default class RiskAndPreventionMeasures extends FormItemEditorController {
    lookups = {
        codeElements: [],
        iconMap: {}
    };

    constructor(props) {
        super(props);
        this.state = { ...this.lookups };
    }

    componentDidMount() {
        this.subscriptions = {
            lookups: arxs.Api.lookups.subscribe(this.lookups, lookups => this.setState({ ...lookups }, this.refresh))
        };
    }

    componentWillUnmount() {
        if (this.subscriptions) {
            this.subscriptions.lookups.dispose();
        }
    }

    refresh = () => { };

    getDraggableItemComponent() { return DraggableFormListItem };

    renderItems = () => {
        const { field, readOnly } = this.props;
        const { codeElements, iconMap } = this.state;
        const value = field.getter();
        const data = value.data || [];

        const onItemChange = (state) => {
            let exists = data.some(item => item.id === state.id);
            let newData = exists 
                ? data.map(item => (item.id === state.id ? state : item)) 
                : [...data, state];  // Add new item if it doesn't exist
        
            field.setter({ ...value, data: newData });
        };

        const addLine = () => {
            onItemChange({ id: arxs.uuid.generate() });
        };

        const onDeleteItem = (id) => {
            let newData = (data || []).filter(x => x.id !== id);
            field.setter({ ...value, data: newData });
        };

        return (
            <div className="rpms">
                {this.renderDraggableList(data, { readOnly, codeElements, iconMap, onItemChange, onDeleteItem })}
                {!readOnly && <div className="form-item-control-add-line" onClick={addLine}>
                    {arxs.t("form_editor.section.add_item.title")} <i className="fas fa-plus"></i>
                </div>}
            </div>
        );
    };

    render() {
        return (
            <GlobalContext.Consumer>
                {(context) => (
                    <Fragment>
                        {this.renderBegin()}
                        <div className="form-item-control-container">
                            {this.renderItems(context)}
                        </div>
                        {this.renderEnd()}
                    </Fragment>
                )}
            </GlobalContext.Consumer>
        );
    }
}
