import {
    RecommendationStatus,
    OriginModuleEnum,
    FormStatus,
    LinkType
} from 'infra/api/contracts';
import DocumentSigningWizardController from 'modules/DocumentSigningWizardController';
import arxs from 'infra/arxs';

class RecommendationWizard extends DocumentSigningWizardController {
    getRequiredFields(getCurrentFieldValue) {
        return [];
    }

    validate(stateProxy, getCurrentFieldValue, preValidation) {
        this.validateSignature(getCurrentFieldValue, preValidation);

        const needsSignature = getCurrentFieldValue("needsSignature");
        const status = getCurrentFieldValue("status");
        const legalStructure = getCurrentFieldValue("legalStructure");
        const branch = getCurrentFieldValue("branch");

        if (!branch && !legalStructure) {
            preValidation["context"] = { error: arxs.t("wizard.validation.field_is_required", { field: arxs.t(`field.context`) }) };
        }

        if (needsSignature && status && status === RecommendationStatus.Active) {
            preValidation["status"] = { error: arxs.t("wizard.validation.status_signature_mismatch") }
        }

        return preValidation;
    }

    setSubject = (stateProxy) => {
        return new Promise((resolve, reject) => {
            const data = stateProxy.getter("data");
            const pristine = stateProxy.getter("pristine");

            const getValue = this.buildGetValue(stateProxy);
            const context = getValue("context");
            const outboundLinks = getValue("outboundLinks")
            if (context) {
                const newSubjectLink = { type: LinkType.Subject, id: context.id, module: context.module }

                let newData = { ...pristine, ...data };
                if (!outboundLinks) {
                    const newOutboundLinks = [newSubjectLink];
                    newData.outboundLinks = newOutboundLinks;
                } else {
                    const existingOutboundLinks = outboundLinks.filter(x => !x.type === LinkType.Subject);
                    existingOutboundLinks.push(newSubjectLink);
                    newData.outboundLinks = existingOutboundLinks;
                }

                stateProxy.setter({ data: { ...newData } }, resolve)
            }


        })
    }

    selectForm = (stateProxy) => {
        return new Promise((resolve, reject) => {
            const data = stateProxy.getter("data");

            const getValue = this.buildGetValue(stateProxy);

            let legalStructure = getValue("legalStructure");
            let branch = getValue("branch");

            if (!legalStructure) {

                const ref = getValue("context");

                if (ref) {
                    const subject = arxs.Api.lookups.resolveSubject(ref);
                    if (subject) {
                        legalStructure = subject.legalStructure;
                        branch = subject.branch || null;
                    }

                    switch (ref.module) {
                        case OriginModuleEnum.School:
                            branch = ref.id;
                            break;
                        case OriginModuleEnum.SchoolGroup:
                            legalStructure = ref.id;
                            break;
                        default: break;
                    }
                }
            }

            const kind = getValue("kind");
            const type = getValue("type");

            const forms = stateProxy.getter("forms");
            if (forms) {
                const moduleForms = forms.filter(form => form.targetModule === OriginModuleEnum.Consultancy
                    && [FormStatus.Active, FormStatus.ToExpire].includes(form.status)
                    && !form.isDeleted);

                if (!moduleForms.length) return;

                let foundForms = moduleForms;

                if (legalStructure?.id) {
                    foundForms = foundForms.filter(
                        ({ isGlobalScope, legalStructures }) =>
                            isGlobalScope || (legalStructures || []).some(({ id }) => id === legalStructure.id)
                    );

                    if (branch?.id) {
                        foundForms = foundForms.filter(
                            ({ branches, isGlobalScope }) =>
                                isGlobalScope || (branches || []).some(({ id }) => id === branch.id)
                        );
                    }
                }

                if (type?.id) {
                    foundForms = foundForms.filter(({ codeElements }) =>
                        (codeElements || []).some(({ id }) => id === type.id)
                    );
                } else if (kind?.id) {
                    foundForms = foundForms.filter(({ codeElements }) =>
                        (codeElements || []).some(({ id }) => id === kind.id)
                    );
                }

                if (foundForms.length === 1) {
                    const [selectedForm] = foundForms;
                    const formDefinition = { link: { id: selectedForm.id } };

                    stateProxy.setter(
                        { data: { ...data, formDefinition } },
                        resolve
                    );
                }

            }
        })
    }
}
export default new RecommendationWizard();
