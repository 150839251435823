import arxs from "infra/arxs";
import {
  EditFormAction,
  TaskStatus,
  LinkType,
  RelationshipType,
  OriginModuleEnum,
} from "infra/api/contracts";
import Toaster from "components/util/Toaster";
import { createInputPopup } from "components/shell/InputPopup/InputPopup";
import GlobalActions from "modules/GlobalActions";
import ClassicActions from "modules/ClassicActions";

export default class TasklikeActions {
  constructor(module) {
    this.module = module;

    this.getActions.bind(this);
    this.getGlobalActions.bind(this);
    this.complete.bind(this);
    this.completeForm.bind(this);
    this.verifyForm.bind(this);
    this.activate.bind(this);
    this.directActivate.bind(this);
    this.setInProcess.bind(this);
    this.verify.bind(this);
    this.hold.bind(this);
    this.unhold.bind(this);
    this.createFollowUp.bind(this);
    this.createTasklike.bind(this);
    this.amendActualDuration.bind(this);
  }

  getConfiguration(arxs) {
    throw "Not implemented";
  }

  _config = null;
  get config() {
    if (!this._config) {
      this._config = this.getConfiguration(arxs);
    }
    return this._config;
  }

  get path() {
    return this.config.path;
  }

  get resource() {
    return this.config.resource;
  }

  get translations() {
    return this.config.translations;
  }

  get createCompletePopup() {
    return this.config.createCompletePopup;
  }

  get createAmendActualDurationPopup() {
    return this.config.createAmendActualDurationPopup;
  }

  get createFollowUpPopup() {
    return this.config.createFollowUpPopup;
  }

  getActions(module) {
    return GlobalActions.getActions(module)
      .concat(ClassicActions.getActions(module))
      .concat([
        {
          name: "complete",
          module: module,
          icon: arxs.actions.icons["complete"],
          getTitle: () => arxs.actions.titles["complete"],
          onClick: (state) => this.complete(state),
        },
        {
          name: "complete_form",
          module: module,
          icon: arxs.actions.icons["complete_form"],
          getTitle: () => arxs.actions.titles["complete_form"],
          onClick: (state) => this.editForm(state),
        },
        {
          name: "amend_actual_duration",
          module: module,
          icon: arxs.actions.icons["amend_actual_duration"],
          getTitle: () => arxs.actions.titles["amend_actual_duration"],
          onClick: (state) => this.amendActualDuration(state),
        },
        {
          name: "verify",
          module: module,
          icon: arxs.actions.icons["verify"],
          getTitle: () => arxs.actions.titles["verify"],
          onClick: (state) => this.verify(state),
        },
        {
          name: "verify_form",
          module: module,
          icon: arxs.actions.icons["verify_form"],
          getTitle: () => arxs.actions.titles["verify_form"],
          onClick: (state) => this.editForm(state),
        },
        {
          name: "hold",
          module: module,
          icon: arxs.actions.icons["hold"],
          getTitle: () => arxs.actions.titles["hold"],
          onClick: (state) => this.hold(state),
        },
        {
          name: "unhold",
          module: module,
          icon: arxs.actions.icons["unhold"],
          getTitle: () => arxs.actions.titles["unhold"],
          onClick: (state) => this.unhold(state),
        },
        {
          name: "activate",
          module: module,
          icon: arxs.actions.icons["accept"],
          getTitle: () => arxs.actions.titles["activate"],
          onClick: (state) => this.activate(state),
        },
        {
          name: "create_follow_up",
          module: module,
          icon: arxs.actions.icons["create_follow_up"],
          getTitle: () =>
            arxs.actions.titles[`create_follow_up_${module.toLowerCase()}`],
          onClick: (state) => this.createFollowUp(state),
        },
        {
          name: "transfer_to_myp",
          module: module,
          icon: arxs.actions.icons["transfer_to_myp"],
          getTitle: () => arxs.actions.titles["transfer_to_myp"],
          onClick: (state) => this.transferToMultiYearPlan(state),
        },
      ]);
  }

  getGlobalActions(module) {
    return [];
  }

  areFormRequirementMet = (record) => {
    const formCompletedResult = arxs.getFormCompletionResult(
      record.formDefinition,
      record.formValues,
      (record.subjects || []).map(subject => subject.id) || []
    );

    if (formCompletedResult.isCompleted) {
      return true;
    }

    Toaster.error(this.translations["not_all_required_fields_filled"]);
    return false;
  };

  complete(state, hasBeenChecked) {
    const context = state.context;
    const id = (state.ids && state.ids[0]) || state.item.objectId;

    const executeAction = (data) => {
      this.resource
        .complete(data)
        .then(() =>
          Toaster.success(this.translations["completed_confirmation"])
        );

      context.popup.close();
    };

    let isChecked = hasBeenChecked;

    if (!isChecked) {
      const record = arxs.Api.lookups.resolveSubject({
        module: this.module,
        id: id,
      });
      isChecked = this.areFormRequirementMet(record);
    }

    if (isChecked) {
      const completePopup = this.createCompletePopup(
        { id },
        executeAction,
        context.popup.close
      );

      context.popup.show(completePopup);
    }
  }

  editForm(state) {
    const { cards, history } = state;
    const card = cards[0];
    if (card && card.id && card.module === this.module) {
      history.push({
        pathname: `${this.path}/form/${card.id}`,
      });
    }
  }

  completeForm(state, card, values, action) {
    return this.resource.editForm({ id: card.id, values, action }).then(() => {
      Toaster.success(arxs.t("actions.form.save.confirmation"));
      switch (action) {
        case EditFormAction.Complete:
          return this.complete(state, true);
        case EditFormAction.Verify:
          return this.verify(state, true);
        default:
          return;
      }
    });
  }

  verifyForm(state) {
    this.completeForm(state);
  }

  activate(state) {
    const context = state.context;
    const ids = state.ids || [state.item.objectId];

    const executeAction = (motivation) => {
      const data = { motivation: motivation || "", ids: ids };
      this.resource
        .activate(data)
        .then(() =>
          Toaster.success(this.translations["activate_confirmation"])
        );
    };

    const confirmation = createInputPopup(
      context,
      this.translations["activate_confirmation_question"],
      (motivation) => executeAction(motivation),
      true,
      false,
      arxs.t("common.motivation")
    );

    context.inputPopup.show(confirmation);
  }

  amendActualDuration(state) {
    const context = state.context;
    const id = (state.ids && state.ids[0]) || state.item.objectId;

    const executeAction = (data) => {
      context.popup.close();
      this.resource
        .amendActualDuration(data)
        .then(() =>
          Toaster.success(
            arxs.t("actions.amend_actual_duration_popup.confirmation")
          )
        );
    };

    const amendActualDurationPopup = this.createAmendActualDurationPopup(
      { id },
      executeAction,
      context.popup.close,
      this.module
    );

    context.popup.show(amendActualDurationPopup);
  }

  directActivate(state) {
    const ids = state.ids || [state.item.objectId];

    const data = { ids: ids };
    this.resource
      .activate(data)
      .then(() => Toaster.success(this.translations["activate_confirmation"]));
  }

  setInProcess(state) {
    const context = state.context;
    const ids = state.ids || [state.item.objectId];

    const motivationRequired =
      state.item.card.status !== TaskStatus.Active ? true : false;

    const executeAction = (motivation) => {
      const data = { motivation: motivation || "", ids: ids };
      this.resource
        .resetToDraft(data)
        .then(() =>
          Toaster.success(this.translations["toprocess_confirmation"])
        );
    };

    const confirmation = createInputPopup(
      context,
      this.translations["toprocess_confirmation_question"],
      (motivation) => executeAction(motivation),
      true,
      motivationRequired,
      arxs.t("common.motivation")
    );
    context.inputPopup.show(confirmation);
  }

  verify(state) {
    const context = state.context;
    const ids = state.ids || [state.item.objectId];

    const executeAction = (motivation) => {
      const data = { motivation: motivation || "", ids: ids };
      this.resource
        .verify(data)
        .then(() =>
          Toaster.success(this.translations["verified_confirmation"])
        );
    };

    const confirmation = createInputPopup(
      context,
      this.translations["verify_confirmation_question"],
      (motivation) => executeAction(motivation),
      true,
      true,
      arxs.t("common.motivation")
    );

    context.inputPopup.show(confirmation);
  }

  hold(state) {
    const context = state.context;
    const ids = state.ids || [state.item.objectId];

    const executeAction = (motivation) => {
      const data = { motivation: motivation || "", ids: ids };
      this.resource
        .hold(data)
        .then(() => Toaster.success(this.translations["hold_confirmation"]));
    };

    const confirmation = createInputPopup(
      context,
      this.translations["hold_confirmation_question"],
      (motivation) => executeAction(motivation),
      true,
      true,
      arxs.t("common.motivation")
    );

    context.inputPopup.show(confirmation);
  }

  unhold(state) {
    const context = state.context;
    const ids = state.ids || [state.item.objectId];

    const executeAction = (motivation) => {
      const data = { motivation: motivation || "", ids: ids };
      this.resource
        .unhold(data)
        .then(() => Toaster.success(this.translations["unhold_confirmation"]));
    };

    const confirmation = createInputPopup(
      context,
      this.translations["unhold_confirmation_question"],
      (motivation) => executeAction(motivation),
      true,
      true,
      arxs.t("common.motivation")
    );

    context.inputPopup.show(confirmation);
  }

  createFollowUp(state) {
    const context = state.context;
    const { cards } = state;
    if (cards.length === 1) {
      const card = cards[0];
      const previousRecord = arxs.Api.lookups.resolveSubject(card);

      const executeAction = (data) => {
        const { targetDate } = data;
        const succeeds = {
          id: previousRecord.id,
          module: previousRecord.module,
          type: LinkType.SucceededBy,
        };

        const mapReferenceId = ((_) => {
          const idMap = {};
          return (id) => {
            const newId = idMap[id] || arxs.uuid.generate();
            idMap[id] = newId;
            return newId;
          };
        })();

        const mapAttachments = (ai) => {
          if (!ai) return ai;

          const imageAttachmentType = "Image";
          const imageAttachments = ai.attachments
            .filter((x) => x.type === imageAttachmentType)
            .reduce(
              (result, current) => [
                {
                  type: current.type,
                  value: result
                    .flatMap((x) => x.value)
                    .concat(
                      current.value.map((x) => ({
                        ...x,
                        id: mapReferenceId(x.id),
                      }))
                    ),
                },
              ],
              []
            );
          const documentAttachments = ai.attachments
            .filter((x) => x.type !== imageAttachmentType)
            .map((x) => ({
              type: x.type,
              value: x.value.map((v) => ({ ...v, id: mapReferenceId(v.id) })),
            }));
          return {
            attachments: imageAttachments.concat(documentAttachments),
            storedFiles: ai.storedFiles.map((x) => ({
              ...x,
              id: mapReferenceId(x.id),
            })),
            documents: ai.documents.map((x) => ({
              ...x,
              id: mapReferenceId(x.id),
            })),
          };
        };

        const attachmentInfo = mapAttachments(previousRecord.attachmentInfo);
        const responsible = previousRecord.relationships
          .filter((x) => x.type === RelationshipType.Responsible)
          .map((x) => x.employee)[0];
        const relationships = previousRecord.relationships
          .filter((x) => x.type !== RelationshipType.Responsible)
          .filter((x) => x.type !== RelationshipType.Assignee)
          .concat(data.relationships);

        const nextRecord = {
          ...previousRecord,
          id: undefined,
          status: TaskStatus.Active,
          attachmentInfo,
          responsible,
          relationships,
          succeeds,
          formValues: {},
          targetDate,
        };

        context.popup.close();

        return this.resource.put(nextRecord).then(() => {
          Toaster.success(this.translations["create_follow_up_confirmation"]);
        });
      };

      const followUpPopup = this.createFollowUpPopup(
        previousRecord,
        executeAction,
        context.popup.close,
        this.translations["create_follow_up"]
      );

      context.popup.show(followUpPopup);
    }
    return new Promise(() => { });
  }

  allowedSubjectModules = [
    OriginModuleEnum.SchoolGroup,
    OriginModuleEnum.School,
    OriginModuleEnum.Building,
    OriginModuleEnum.Room,
    OriginModuleEnum.Labourmeans,
    OriginModuleEnum.EquipmentInstallation,
    OriginModuleEnum.Pbm,
    OriginModuleEnum.HazardousSubstance,
    OriginModuleEnum.IntangibleAsset,
    OriginModuleEnum.CombinedInstallation,
    OriginModuleEnum.Consultancy,
  ];

  tasklikeModules = [
    OriginModuleEnum.Task,
    OriginModuleEnum.PeriodicControl,
    OriginModuleEnum.PeriodicMaintenance,
    OriginModuleEnum.RiskAnalysis,
  ];

  getTaskLikeCode(tasklikeModule, originModule, subjectModule) {
    const getModuleTranslation = (module) => {
      switch (module) {
        case OriginModuleEnum.PeriodicMaintenance:
          return "Maintenance";
        case OriginModuleEnum.PeriodicControl:
          return "Control";
        default:
          return module;
      }
    };
    return subjectModule
      ? `${getModuleTranslation(tasklikeModule)}.${getModuleTranslation(
        originModule
      )}.${getModuleTranslation(subjectModule)}`
      : `${getModuleTranslation(tasklikeModule)}.${getModuleTranslation(
        originModule
      )}`;
  }

  createTasklike(state, originModule, tasklikeModule, route) {
    const { cards, history, context } = state;
    const status = TaskStatus.Active;
    let subjects, origins, kind, type;

    if (originModule === OriginModuleEnum.CombinedInstallation) {
      if (cards.length === 1) {
        const handle = (recordPerSubject) => {
          if (recordPerSubject === undefined) {
            subjects = cards.map(card => ({ id: card.id, module: card.module }));
          } else {
            subjects = cards
              .flatMap(card => card.outboundLinks)
              .filter(link => link.type === LinkType.PartOf)
              .map(link => ({ id: link.id, module: link.module }));
          }

          history.push({
            pathname: route,
            state: { subjects, status, recordPerSubject },
          });
        };

        const options = [{
          title: arxs.t("kanban.actions.on_the_combined_installation"),
          handle: () => handle(),
        }, {
          title: arxs.t("kanban.actions.multi_subject"),
          handle: () => handle(false),
        }, {
          title: arxs.t("kanban.actions.one_per_subject"),
          handle: () => handle(true),
        }];

        context.optionPopup.show(
          arxs.t(this.translations["create_task"]),
          options
        );
      }

      return new Promise(() => { });
    }

    if (cards.length > 1) {
      if (!this.allowedSubjectModules.includes(originModule)) {
        return new Promise(() => { });
      }

      const handle = (recordPerSubject) => {
        subjects = cards.map(card => ({ id: card.id, module: card.module }));

        history.push({
          pathname: route,
          state: { subjects, status, recordPerSubject },
        });
      };

      const options = [{
        title: arxs.t("kanban.actions.multi_subject"),
        handle: () => handle(false),
      }, {
        title: arxs.t("kanban.actions.one_per_subject"),
        handle: () => handle(true),
      }];

      context.optionPopup.show(
        arxs.t(this.translations["create_task"]),
        options
      );

    } else if (cards.length === 1) {
      const card = cards[0];

      const rootCodes = {
        [OriginModuleEnum.Task]: "Task.Kind",
        [OriginModuleEnum.PeriodicControl]: "Control.ControlType",
        [OriginModuleEnum.PeriodicMaintenance]: "PeriodicMaintenance.MaintenanceType",
        [OriginModuleEnum.RiskAnalysis]: "RiskAnalysis.Kind",
      };

      if (this.allowedSubjectModules.includes(originModule)) {
        subjects = [{ id: card.id, module: card.module }];
      } else if (originModule === OriginModuleEnum.ActivityEntry) {
        const original = arxs.Api.lookups.resolveSubject(card);
        origins = [
          { id: original.id, module: original.module, type: LinkType.Origin },
        ];
        if (card.location) {
          subjects = [{ id: original.location.id, module: OriginModuleEnum.Room }];
        } else if (original.building) {
          subjects = [{
            id: original.building.id,
            module: OriginModuleEnum.Building,
          }];
        } else if (original.branch) {
          subjects = [{ id: original.branch.id, module: OriginModuleEnum.School }];
        } else if (original.legalStructure) {
          subjects = [{
            id: original.legalStructure.id,
            module: OriginModuleEnum.SchoolGroup,
          }];
        }
      } else if (originModule === OriginModuleEnum.IncidentManagement) {
        const original = arxs.Api.lookups.resolveSubject(card);
        origins = [
          { id: original.id, module: original.module, type: LinkType.Origin },
        ];
        if ((original.subjects || []).some((x) => x)) {
          subjects = original.subjects;
        } else {
          if (original.location) {
            subjects = [{
              id: original.location.id,
              module: OriginModuleEnum.Room,
            }];
          } else if (original.building) {
            subjects = [{
              id: original.building.id,
              module: OriginModuleEnum.Building,
            }];
          } else if (original.branch) {
            subjects = [{
              id: original.branch.id,
              module: OriginModuleEnum.School,
            }];
          } else if (original.legalStructure) {
            subjects = [{
              id: original.legalStructure.id,
              module: OriginModuleEnum.SchoolGroup,
            }];
          }
        }
      } else if (originModule === OriginModuleEnum.GlobalPreventionPlan) {
        const original = arxs.Api.lookups.resolveSubject(card);
        origins = [
          { id: original.id, module: original.module, type: LinkType.Origin },
        ];
        if (original.subject) {
          subjects = [original.subject];
        } else {
          if (original.location) {
            subjects = [{
              id: original.location.id,
              module: OriginModuleEnum.Room,
            }];
          } else if (original.building) {
            subjects = [{
              id: original.building.id,
              module: OriginModuleEnum.Building,
            }];
          } else if (original.branch) {
            subjects = [{
              id: original.branch.id,
              module: OriginModuleEnum.School,
            }];
          } else if (original.legalStructure) {
            subjects = [{
              id: original.legalStructure.id,
              module: OriginModuleEnum.SchoolGroup,
            }];
          }
        }
      } else if (this.tasklikeModules.includes(tasklikeModule)) {
        const original = arxs.Api.lookups.resolveSubject(card);
        if (original.subjects) {
          subjects = original.subjects;
          origins = [
            { id: original.id, module: original.module, type: LinkType.Origin },
          ];
          const codeElements = arxs.Api.lookups.getLookup("codeElements");
          const rootCode = rootCodes[tasklikeModule];
          const parentCode = this.getTaskLikeCode(tasklikeModule, originModule);

          const subjectModules = original.subjects.map(x => x.module).distinct();
          if (subjectModules.length > 0) {
            const moduleCode = this.getTaskLikeCode(
              tasklikeModule,
              originModule,
              subjectModules[0]
            );
            const kindCodeElement = codeElements[rootCode][0].children.filter(
              (x) => x.code === parentCode
            )[0];
            if (kindCodeElement) {
              const typeCodeElement = kindCodeElement.children[0].children.filter(
                (x) => x.code === moduleCode
              )[0];
              if (typeCodeElement) {
                kind = { id: kindCodeElement.id };
                type = { id: typeCodeElement.id };
              }
            }
          }
        } else {
          Toaster.error(this.translations["no_subject"]);
          return;
        }
      } else {
        arxs.logger.error(
          "Trying to createTasklike for unsupported module {module}",
          tasklikeModule
        );
        return;
      }

      if (subjects.length > 0) {
        history.push({
          pathname: route,
          state: { subjects, origins, status, kind, type, recordPerSubject: false },
        });
      }
    }

    return new Promise(() => { });
  }

  transferToMultiYearPlan = (state) => {
    let { cards, history, item } = state;

    if (!cards && item) {
      cards = [item.card];
    }

    if (cards.length === 1) {
      const origin = cards[0];
      const origins = [
        { id: origin.id, module: origin.module, type: LinkType.Origin },
      ];
      let {
        legalStructure,
        branch,
        building,
        location,
        geoLocation,
        subject,
        title,
        description,
        estimatedCost,
        estimatedDuration,
        tags,
      } = origin;

      if (!subject) {
        subject = origin.subjects[0];
      }

      const mapReferenceId = ((_) => {
        const idMap = {};
        return (id) => {
          const newId = idMap[id] || arxs.uuid.generate();
          idMap[id] = newId;
          return newId;
        };
      })();

      const mapAttachments = (ai) => {
        if (!ai) return ai;

        const imageAttachmentType = "Image";
        const imageAttachments = ai.attachments
          .filter((x) => x.type === imageAttachmentType)
          .reduce(
            (result, current) => [
              {
                type: current.type,
                value: result
                  .flatMap((x) => x.value)
                  .concat(
                    current.value.map((x) => ({
                      ...x,
                      id: mapReferenceId(x.id),
                    }))
                  ),
              },
            ],
            []
          );
        const additionalDocumentAttachments = ai.attachments
          .filter((x) => x.type !== imageAttachmentType)
          .reduce(
            (result, current) => [
              {
                type: "AdditionalDocument",
                value: result
                  .flatMap((x) => x.value)
                  .concat(
                    current.value.map((x) => ({
                      ...x,
                      id: mapReferenceId(x.id),
                    }))
                  ),
              },
            ],
            []
          );
        return {
          attachments: imageAttachments.concat(additionalDocumentAttachments),
          storedFiles: ai.storedFiles.map((x) => ({
            ...x,
            id: mapReferenceId(x.id),
          })),
          documents: ai.documents.map((x) => ({
            ...x,
            id: mapReferenceId(x.id),
          })),
        };
      };

      const attachmentInfo = mapAttachments(origin.attachmentInfo);
      const responsible = origin.relationships
        .filter((x) => x.type === RelationshipType.Responsible)
        .map((x) => x.employee)[0];
      const allowedRelationships = [
        RelationshipType.CoResponsible,
        RelationshipType.Cc,
      ];
      const relationships = origin.relationships.filter((x) =>
        allowedRelationships.contains(x.type)
      );
      history.push({
        pathname: `/multiyearplan/create`,
        state: {
          legalStructure,
          branch,
          building,
          location,
          geoLocation,
          subject,
          title,
          description,
          estimatedCost,
          estimatedDuration,
          origins,
          responsible,
          relationships,
          tags,
          attachmentInfo,
          transferFromOrigin: true,
        },
      });
    }
    return new Promise(() => { });
  };
}
