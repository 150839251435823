import React, { Component, Fragment } from 'react';
import arxs from 'infra/arxs';
import Dropzone from 'react-dropzone-uploader'
import { HorizontalSeparator } from 'components/shell/HorizontalSeparator';
import ProgressRing from 'components/controls/ProgressRing';
import Toaster from 'components/util/Toaster';
import GlobalContext from 'infra/GlobalContext';
import DocumentLabel from 'components/controls/documents/DocumentLabel';

import './Upload.scss';
import 'react-dropzone-uploader/dist/styles.css'
import { ObjectDocumentType } from 'infra/api/contracts';

export const createUpload = (module, selectedCards, onSubmit, singleFileDocumentTypes, filteredDocumentTypes, overrideDocumentTypes, correlationKey) => {
  const state = {
    title: arxs.t("controls.upload.title"),
    content: <Upload module={module} selectedCards={selectedCards} onSubmit={onSubmit} singleFileDocumentTypes={singleFileDocumentTypes} filteredDocumentTypes={filteredDocumentTypes} overrideDocumentTypes={overrideDocumentTypes} correlationKey={correlationKey} />
  };

  return state;
};

export const uploadBlobToAzure = async (blobUrl, filename, documentType, correlationKey, contentType) => {
  const response = await fetch(blobUrl);
  const blob = await response.blob();

  const putAuthorization = await arxs.ApiClient.shared.blob.getPutAuthorization(filename, documentType);

  const headers = createAzureBlobHeaders(correlationKey);

  const uploadResponse = await fetch(putAuthorization, {
    method: 'PUT',
    headers,
    body: blob
  });

  if (!uploadResponse.ok) {
    throw new Error(`Failed to upload blob: ${uploadResponse.statusText}`);
  }

  return {
    refId: arxs.uuid.generate(),
    url: decodeURIComponent(uploadResponse.url),
    contentType: contentType || blob.type,
    name: filename
  };
};

export const toBlobTagsString = (tags) => {
  if (!tags) return undefined;

  return Object.entries(tags)
    .map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`)
    .join('&');
};

export const createAzureBlobHeaders = (correlationKey) => ({
  'x-ms-date': new Date().toISOString(),
  'x-ms-version': '2019-12-12',
  'x-ms-blob-type': 'BlockBlob',
  'x-ms-tags': toBlobTagsString({ correlationKey })
});


export class Upload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      uploadFileMeta: [],
      selectedCards: [],
      fileUrlMap: {},
      correlationKey: props.correlationKey,
      uploadMap: {}
    };

    const { selectedCards, module, correlationKey } = props;

    if (!correlationKey || correlationKey === "") {
      this.state.correlationKey = new Date().format_yyyyMMddhhmmsshhh()
    }

    if (module) {
      const metaData = arxs.moduleMetadataRegistry.get(module);

      this.state.module = {
        name: module,
        icon: arxs.modules.icons[module],
        title: arxs.modules.titles[module],
        documentTypes: metaData.allowedDocumentTypes,
        singleFileDocumentTypes: metaData.singleFileDocumentTypes || []
      };
    } else {
      arxs.logger.error("Upload: module props is required.");
      throw "Upload: module props is required.";
    }

    if (selectedCards) {
      this.state.selectedCards = selectedCards;
    };
  }



  getUploadParams = async ({ file, meta }, documentType) => {
    const putAuthorization = await arxs.ApiClient.shared.blob.getPutAuthorization(meta.name, documentType);

    const { fileUrlMap, correlationKey } = this.state;
    fileUrlMap[meta.id] = putAuthorization;
    this.setState({ fileUrlMap });

    const params = {
      meta: meta,
      url: putAuthorization,
      method: 'PUT',
      body: file,
      headers: createAzureBlobHeaders(correlationKey)
    };

    return params;
  }

  handleChangeStatus = (fileWithMeta, status, documentType) => {
    const meta = fileWithMeta.meta;
    const xhr = fileWithMeta.xhr;

    const cancelAndRemove = () => {
      fileWithMeta.cancel();
      fileWithMeta.remove();
    }

    switch (status) {
      case "done":
        const newUploadFileMeta = { meta, blobUrl: encodeURIComponent(decodeURIComponent(xhr.responseURL)), md5: xhr.getResponseHeader("Content-MD5"), preferred: false, documentType: documentType };
        this.setState(prevState => ({ uploadFileMeta: [...prevState.uploadFileMeta, newUploadFileMeta] }));
        break;
      case "removed":
        this.setState(prevState => ({ uploadFileMeta: prevState.uploadFileMeta.filter(x => x.meta.id !== meta.id) }));
        break;
      case "rejected_file_type":
        Toaster.error(arxs.t("controls.upload.error.not_allowed"));
        break;
      case "error_upload":
        Toaster.error(arxs.t("controls.upload.error.upload_error"));
        break;
      case "error_file_size":
        Toaster.error(arxs.t("controls.upload.error.upload_error_filesize"));
        cancelAndRemove();
        break;
      default: break;
    }
  }

  onSubmit = async () => {
    const { fileUrlMap, uploadMap } = this.state;

    if (Object.keys(uploadMap).some(x => x)) {
      Toaster.warning(arxs.t("controls.upload.submit.add_files"));
    } else {

      const files = this.state.uploadFileMeta.map(meta => ({
        url: meta.blobUrl, //.split('?')[0],
        hash: meta.md5,
        type: meta.documentType,
        name: meta.title || meta.meta.name,
        contentType: meta.meta.type,
        isPreferredImage: meta.preferred,
        previewUrl: fileUrlMap[meta.meta.id]
      }));

      this.setState({ uploadFileMeta: [] }, () => this.props.onSubmit({ documents: files }));
    }
  }

  render() {
    const inputComponent = (context, { accept, files, getFilesFromEvent, onFiles }, documentType) => {
      const singleFileDocumentTypes = this.state.module.singleFileDocumentTypes;

      const triggerFilePicker = (accept, capture) => {
        const input = document.createElement("input");
        input.style.display = "none";
        input.type = "file";
        input.accept = accept;
        if (capture === "capture") {
          input.capture = "capture";
        }
        input.multiple = !singleFileDocumentTypes.includes(documentType);
        // iOS requires us to use addEventListener("change") instead of onchange
        input.addEventListener("change", (e) => {
          document.body.removeChild(input);
          const chosenFiles = getFilesFromEvent(e);
          onFiles(chosenFiles);
        });
        // iOS requires us to add the input to the body
        document.body.appendChild(input);
        input.click();
      };

      const handle = () => {
        if (context.platform.isMobile && accept === "image/*") {
          const options = [
            {
              title: arxs.t("controls.upload.image.gallery_source"),
              handle: () => triggerFilePicker("image/*"),
            },
            {
              title: arxs.t("controls.upload.image.camera_source"),
              handle: () => triggerFilePicker("image/*", "capture"),
            }
          ];
          context.optionPopup.show(
            arxs.t("controls.upload.image.pick_image_source"),
            options
          );
        } else {
          triggerFilePicker(accept);
        }
      };

      const empty = (
        <label key="upload-input" className="upload-input-empty" onClick={handle}>
          <i className="fas fa-plus"></i>
          <h3>{arxs.t("controls.upload.input")}</h3>
        </label>
      );

      const notEmpty = (
        <label key="upload-input" className="upload-input" onClick={handle}>
          <i className="fas fa-plus"></i>
        </label>
      );

      if (!singleFileDocumentTypes.includes(documentType)) {
        return files && files.length > 0 ? notEmpty : empty;
      } else {
        if (!files || files.length === 0) {
          return empty;
        }
      }

      return <Fragment />;
    };

    const previewComponent = (props, documentType, context) => {
      const { meta, fileWithMeta } = props;
      const { name, percent } = meta
      const url = meta.previewUrl;

      let uploadFileMeta = this.state.uploadFileMeta;
      let instanceMeta = uploadFileMeta.find((m) => m.meta.id === meta.id) || {};

      const cancelAndRemove = () => {
        fileWithMeta.cancel();
        fileWithMeta.remove();
      };

      const setPreferred = () => {
        instanceMeta.preferred = !instanceMeta.preferred;

        let previousPreferredMeta = uploadFileMeta.firstOrDefault(x => x.preferred === true && x.meta.id !== meta.id);

        if (previousPreferredMeta) {
          previousPreferredMeta.preferred = false;
          uploadFileMeta[uploadFileMeta.indexOf(previousPreferredMeta)] = previousPreferredMeta;
        }

        uploadFileMeta[uploadFileMeta.indexOf(instanceMeta)] = instanceMeta;

        this.setState({ uploadFileMeta: uploadFileMeta });
      }

      const onRename = (value, id) => {
        instanceMeta.title = value;
        uploadFileMeta[uploadFileMeta.indexOf(instanceMeta)] = instanceMeta;

        this.setState({ uploadFileMeta: uploadFileMeta });
      }

      if (["uploading", "done"].includes(meta.status)) {

        if ([ObjectDocumentType.Image, ObjectDocumentType.FormImage, ObjectDocumentType.ActivityImage].includes(documentType)) {
          let style = null;
          if (percent === 100) {
            style = { "background": `url(${url}) no-repeat center`, "backgroundSize": "cover" };
          }

          return (
            <div className="upload-preview-container">
              <div className="upload-preview" style={style} title={name}>
                {percent < 100 && <ProgressRing radius={22} stroke={3} progress={percent} onClick={cancelAndRemove} />}
                <div className="is-preferred">
                  {instanceMeta.preferred && <i className="fas fa-check-double"></i>}
                </div>
              </div>
              <div className="upload-preview-actions">
                <div className="upload-preview-remove" onClick={cancelAndRemove} title={arxs.t("controls.upload.delete")}>
                  <i className="far fa-trash-alt"></i>
                </div>
                <div className="upload-preview-set-preferred" onClick={setPreferred} title={arxs.t("controls.upload.set_preferred_image")}>
                  <i className="fas fa-check-circle"></i>
                </div>
              </div>
            </div>
          );
        }

        return (
          <DocumentLabel
            id={name}
            contentType={instanceMeta && instanceMeta.meta && instanceMeta.meta.type}
            documentType={documentType}
            name={instanceMeta.title || name}
            uploadAnimationPercentage={percent}
            status={meta.status}
            onDelete={cancelAndRemove}
            allowEdit
            onRename={onRename}>
          </DocumentLabel>
        );
      }
    };

    const uniqueNumbers = () => {
      const cards = this.state.selectedCards;
      return cards.map((card, i) => <div className="upload-card-uniquenumber" key={`card-${i}`}>{card.uniqueNumber}</div>)
    };

    const layout = ({ input, previews, submitButton, dropzoneProps, files }, documentType, uploadMap) => {
      const metas = (files || []).map(f => f.meta);
      if (metas.some(x => !x.status || x.status !== "done")) {
        if (!Object.keys(uploadMap).some(x => x === documentType)) {
          this.setState({ uploadMap: { ...uploadMap, [documentType]: true } });
        }
      } else {
        if (Object.keys(uploadMap).some(x => x === documentType)) {
          let newUploadMap = { ...uploadMap };
          delete newUploadMap[documentType];
          this.setState({ uploadMap: newUploadMap });
        }
      }
      return (
        <div {...dropzoneProps}>
          <header>{arxs.documentTypes.titles[documentType.toLowerCase()]}</header>
          <div className="upload-dropzone-body">
            {input}
            {previews}
          </div>
        </div>
      )
    }

    const dropZones = (context) => {
      let documentTypes = this.state.module.documentTypes;

      if (this.props.filteredDocumentTypes && this.props.filteredDocumentTypes.some(x => x)) {
        documentTypes = documentTypes.filter(x => this.props.filteredDocumentTypes.contains(x));
      }

      if (this.props.overrideDocumentTypes) {
        documentTypes = this.props.overrideDocumentTypes;
      }

      return documentTypes.map((documentType) => {
        let isImage = [ObjectDocumentType.Image, ObjectDocumentType.FormImage, ObjectDocumentType.ActivityImage].contains(documentType);
        const accept = isImage ? "image/*" : "*";
  
        return <Dropzone key={`dz-${documentType}`}
          classNames={isImage ? { dropzone: "upload-dropzone image" } : { dropzone: "upload-dropzone document" }}
          getUploadParams={(arg1) => this.getUploadParams(arg1, documentType)}
          onChangeStatus={(arg1, arg2) => this.handleChangeStatus(arg1, arg2, documentType)}
          LayoutComponent={(props) => layout(props, documentType, this.state.uploadMap)}
          accept={accept}
          PreviewComponent={(props) => previewComponent(props, documentType, context)}
          InputComponent={(props) => inputComponent(context, props, documentType)}
          maxFiles={this.state.module.singleFileDocumentTypes.includes(documentType) ? 1 : undefined}
          minSizeBytes={1}
        ></Dropzone>;
      })
    }

    const renderSubmit = () => {
      const { uploadFileMeta, uploadMap } = this.state;
      if (uploadFileMeta.length > 0 && !Object.keys(uploadMap).some(x => x)) {
        return <Fragment>
          <HorizontalSeparator />
          <div className="upload-submit" onClick={this.onSubmit}>
            {arxs.t("controls.upload.submit.add_files", { number: this.state.uploadFileMeta.length })}<i className="fas fa-plus"></i>
          </div>
        </Fragment>;
      };
    }

    return (
      <GlobalContext.Consumer>
        {(context) => <div className="upload">

          {this.state.selectedCards && this.state.selectedCards.length > 0 &&
            <Fragment><div className="upload-cardlist-info">
              {uniqueNumbers()}
            </div>
              <HorizontalSeparator />
            </Fragment>}
          <div className="upload-dropzones">
            {dropZones(context)}
          </div>
          {renderSubmit()}
        </div>}
      </GlobalContext.Consumer>
    );
  }
}